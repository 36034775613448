<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-tp-title">
      {{$t('maintenance.third_parties.create.title')}}
    </h4>

    <div class="card" style="margin-bottom:50px;">
      <div class="card-body">
        <div class="">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-tp-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-tp-form">
            <div class="form-container">
              <div class="row">
                <div class="col-sm-6 pl-5 pr-3">

                  <h5 class="mb-3">
                    <label>{{ $t('maintenance.third_parties.create.name_section_label') }}</label>
                  </h5>

                  <b-form-group>
                    <label qid="create-tp-name-label">
                      {{ $t('maintenance.third_parties.create.name_label') }}
                    </label>
                    <span class="text-red">*</span>
                    <b-form-input
                      qid="create-tp-form-name"
                      type="text"
                      v-model="tpForm.name"
                      required
                      :maxLength="255"
                      :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-tp-contact-label">
                      {{ $t('maintenance.third_parties.create.contract_reference_label') }}
                    </label>
                    <b-form-input
                      qid="create-tp-form-contact"
                      type="text"
                      v-model="tpForm.reference"
                      :maxLength="255"
                      :placeholder="$t('maintenance.third_parties.create.contract_reference_placeholder')"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-tp-description-label">
                      {{ $t('maintenance.third_parties.create.description_label') }}
                    </label>
                    <b-form-textarea
                      qid="create-tp-form-description"
                      v-model="tpForm.description"
                      rows="6"
                      max-rows="6"
                      :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                    ></b-form-textarea>
                  </b-form-group>

                  <h5 class="mb-2 mt-4">
                    <label>{{ $t('maintenance.third_parties.create.ownership_section_label') }}</label>
                  </h5>
                  <b-form-group>
                    <label qid="create-p-users-label">
                      {{ $t('processes.create.owners_label') }}
                    </label>
                    <multiselect
                        v-model="selectedUsers"
                        :options="users"
                        :multiple="true"
                        :taggable="false"
                        track-by="slug"
                        :placeholder="$t('processes.create.owners_placeholder')"
                        label="name"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        @remove="removeUserFromDropdown"
                        @search-change="loadUsers"
                        qid="create-p-users-select"
                    >
                      <template slot="tag" slot-scope="props">
                        <span></span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <Avatar :object="props.option" size="xs"></Avatar>
                        <span class="ml-2">
                          {{ props.option.name }}
                        </span>
                      </template>
                    </multiselect>
                    <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
                      <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                        <div class="row">
                          <div class="col-sm-1">
                            <font-awesome-icon
                              icon="star"
                              :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                              class="cursor-pointer"
                              @click="setOwner(index)"
                            />
                          </div>
                          <div class="col-sm-10">
                            <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                            <span class="ml-2">{{ user.name }}</span>
                          </div>
                          <div class="col-sm-1">
                            <font-awesome-icon
                              icon="times"
                              class="cursor-pointer"
                              @click="removeUser(index)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="text-light">
                      * {{ $t('maintenance.third_parties.create.owners_tip') }}
                    </span>
                  </b-form-group>

                </div>
                <div class="col-sm-6 pl-3 pr-5">

                  <h5 class="mb-3">
                    <label>{{ $t('maintenance.third_parties.create.groups_section_label') }}</label>
                  </h5>

                  <b-form-group>
                    <label qid="create-ac-pg-label">
                      {{ $t('maintenance.third_parties.create.vendor_label') }}
                    </label>
                    <span class="text-red">*</span>
                    <div class="row">
                      <div class="col-sm-8">
                        <multiselect
                            v-model="selectedVendors"
                            :options="vendorItems"
                            :multiple="true"
                            :taggable="false"
                            track-by="slug"
                            :placeholder="$t('maintenance.third_parties.create.vendor_placeholder')"
                            label="name"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            @search-change="loadVendors"
                            qid="create-p-users-select"
                        >
                          <template slot="tag" slot-scope="props">
                            <Avatar :object="props.option" size="xs"></Avatar>
                            <span class="ml-1">
                              {{ props.option.name }}
                            </span>
                            <font-awesome-icon
                              icon="times"
                              class="cursor-pointer mr-2"
                              @click="removeVendor(props.option)"
                            />
                          </template>
                          <template slot="option" slot-scope="props">
                            <Avatar :object="props.option" size="xs"></Avatar>
                            <span class="ml-1">
                              {{ props.option.name }}
                            </span>
                          </template>
                        </multiselect>
                      </div>
                      <div class="col-sm-4">
                        <VendorCreateForm @reloadContent="updateVendors"></VendorCreateForm>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group :class="{'multiselect--show-input': typeInput.length}">
                    <label qid="create-tp-type-label">
                      {{ $t('maintenance.third_parties.create.type_label') }}
                    </label>
                    <multiselect
                        v-model="tpForm.type"
                        :options="types"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        :placeholder="$t('maintenance.third_parties.create.type_placeholder')"
                        label="type"
                        :preserveSearch="true"
                        @search-change="loadTypes"
                        qid="create-tp-form-type"
                    >
                      <template slot="noResult" slot-scope="props">
                        {{ $t('maintenance.data_subjects.create.subjects_no_results') }}
                      </template>
                    </multiselect>
                  </b-form-group>
                  <b-form-group>
                    <label qid="create-do-tags-label">
                      {{ $t('processes.create.tags_label') }}
                    </label>
                    <multiselect
                        v-model="selectedTags"
                        :options="tags"
                        :multiple="true"
                        :taggable="true"
                        :select-label="$t('system.dropdown_select_label')"
                        :selected-label="$t('system.dropdown_selected_label')"
                        :deselect-label="$t('system.dropdown_deselect_label')"
                        @search-change="loadTags"
                        track-by="name_translated"
                        :placeholder="$t('processes.create.tags_placeholder')"
                        label="name_translated"
                        qid="create-do-tags-select"
                        @tag="addTag"
                    ></multiselect>
                  </b-form-group>

                  <h5 class="mb-3 mt-4">
                    <label>{{ $t('maintenance.third_parties.create.features_section_label') }}</label>
                  </h5>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.contract_measures_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.contract_measures"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.data_privacy_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.data_privacy_feature"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.risk_managements_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.risk_management_feature"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.projects_and_assessments_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.project_and_assessment"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.relations_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.relations"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <label>
                        {{ $t('maintenance.third_parties.create.validation_feature_label') }}
                      </label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-form-radio-group
                        v-model="tpForm.validation_activation"
                        :options="checklistOptions"
                        qid="create-tp-contract-measures"
                      ></b-form-radio-group>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-tp-cancel-button"
              >
                {{ $t('maintenance.third_parties.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        qid="create-tp-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('maintenance.third_parties.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';
  import VendorCreateForm from '@/views/ThirdParty/VendorCreateForm.vue';

  export default {
    name: 'CreateContract',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.third_parties.title'),
            to: '/contracts'
          },
          {
            text: this.$t('maintenance.third_parties.create.title'),
            active: true
          }
        ],
        tpForm: {
          name: '',
          description: '',
          reference: '',
          type: [],
          client:'',
          contract_measures: 1,
          data_privacy_feature: 1,
          risk_management_feature: 1,
          project_and_assessment: 1,
          relations: 1,
          validation_activation: 1,
          links: [],
          tags: []
        },
        error: '',
        inputLink: '',
        selectedFiles: [],
        selectedDataObjects: [],
        inspectionItems: [
          { name: this.$t('maintenance.third_parties.inspection.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.inspection.external_audit'), value: 'External audit' },
          { name: this.$t('maintenance.third_parties.inspection.site_visit'), value: 'Site visit' },
          { name: this.$t('maintenance.third_parties.inspection.third-party_self-audit'), value: 'Third-party self-audit' }
        ],
        dpaItems: [
          { name: this.$t('maintenance.third_parties.data_processor_agreement.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.n/a'), value: 'N/A' }
        ],
        dpaContractTypes: [
          { name: this.$t('maintenance.third_parties.dpa_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        transfersDisclosuresThirdCountries : [
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
        ],
        thirdCountryContractTypes: [
          { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        checklistOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        types: [],
        typeInput: '',
        dataObjects: [],
        buttonDisabled: false,
        tags: [],
        selectedTags: [],
        selectedUsers: [],
        selectedVendors: [],
        vendorItems: [],
        users: [],
        newTags: []
      }
    },
    components: {
      Datepicker,
      Avatar,
      VendorCreateForm
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadTypes()
        this.loadTags()
        this.loadVendors()
        this.loadUsers()
      }
    },
    methods: {
      goToList() {
        this.$router.push('/contracts')
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      onFileChange() {
        let files = document.querySelector('#file');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.tpForm.links.push(this.inputLink)
          // Reset link input form
          this.inputLink = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.tpForm.links.splice(index, 1);
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeVendor(item) {
        let selectedItem = item
        let index = _.findIndex(this.selectedVendors, function(object) { return object.id == item.id; });
        this.$set(this.selectedVendors, index, selectedItem)
        // Remove item from selected users array
        this.selectedVendors.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        var moment = require('moment');
        let formData = new FormData();
        let sizeError = false;
        let selectedOwner = false;

        // Call handle link function to gather link from link input
        this.handleLink()

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();


        // Append list of links to FormData
        for (var i = 0; i < this.tpForm.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.tpForm.links[i]));
        }

        formData.append("name", this.tpForm.name);
        formData.append("description", this.tpForm.description);
        formData.append("reference", this.tpForm.reference);
        formData.append("contract_measures", this.tpForm.contract_measures);
        formData.append("data_privacy_feature", this.tpForm.data_privacy_feature);
        formData.append("risk_management_feature", this.tpForm.risk_management_feature);
        formData.append("project_and_assessment", this.tpForm.project_and_assessment);
        formData.append("relations", this.tpForm.relations);
        formData.append("validation_activation", this.tpForm.validation_activation);
        formData.append("client", this.getClient.slug);

        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            formData.append("tags[]", this.selectedTags[i].name_translated);
          }
        }

        if (this.tpForm.type && this.tpForm.type.type) {
          formData.append("type", this.tpForm.type.type);
        } else {
          if (this.typeInput && this.typeInput.length) {
            formData.append("type", this.typeInput);
          }
        }

        // Append list of users to FormData
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            formData.append("users[]", this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              formData.append("owner", this.selectedUsers[i].id);
            }
          }
        } else {
          selectedOwner = true;
        }

        if (this.selectedVendors && this.selectedVendors.length) {
          for (var i = 0; i < this.selectedVendors.length; i++) {
            formData.append("vendors[]", this.selectedVendors[i].id);
          }
        }

        if (selectedOwner && this.selectedVendors && this.selectedVendors.length) {
          this.buttonDisabled = true;
          // Post FormData object to third parties API
          piincHttp.post('/third-parties', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('maintenance.third_parties.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'contracts.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors['files.0']) {
                self.error += ' ' + error.data.errors['files.0'][0]
              }
            }
          })
        } else {
          this.error = ''
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (!this.selectedVendors || (this.selectedVendors && !this.selectedVendors.length)) {
            this.error +=  ' ' + this.$t('maintenance.third_parties.create.selected_vendors_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadTypes(query) {
        if (query) {
          this.typeInput = query;
        } else {
          this.typeInput = ''
        }
        let self = this;
        piincHttp.get('/third-parties/types', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.types = response.data
          if (self.types && !self.types.length && !query) {
            self.types.push({type: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          if  (self.newTags && self.newTags.length) {
            for (var i = 0; i < self.newTags.length; i++) {
              self.tags.push(self.newTags[i]);
            }
          }
        }, function() {});
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      updateVendors(object) {
        this.loadVendors()
      },
      loadVendors(searchQuery) {
        let self = this;
        piincHttp.get('vendors', { params:
          {
            client: this.getClient.slug,
            search: searchQuery !== '' ? searchQuery : undefined,
            parent: this.getClient.parent ? 1 : undefined,
            per_page: 100
          }
        }).then(function(response) {
          self.vendorItems = response.data.items
        }, function() {});
      }
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        this.loadTypes()
        this.loadTags()
        this.loadVendors()
        this.loadUsers()
      }
    }
  }
</script>
